import React from 'react'

const Admission = () => {
  return (
    <>
      Admission
    </>
  )
}

export default Admission
