import React from 'react'
import { FaFacebook } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { IoLogoGoogle } from "react-icons/io5";
import { GrInstagram } from "react-icons/gr";
import { RiLinkedinFill } from "react-icons/ri";
import { SlSocialGithub } from "react-icons/sl";

const Footer = () => {
  return (
 <>
<footer class="text-center text-lg-start bg-body-tertiary text-muted">
  <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom" style={{background:"black"}}>
    <div className="me-5 d-none d-lg-block" style={{color:"white"}}>
      <span>Get connected with us on social networks:</span>
    </div>
    <div className="" style={{color:"white"}}>
      <a href="" class="me-4 text-reset">
       <FaFacebook />
      </a>
      <a href="" class="me-4 text-reset">
       <BsTwitterX />
      </a>
      <a href="" class="me-4 text-reset">
       <IoLogoGoogle />
      </a>
      <a href="" class="me-4 text-reset">
       <GrInstagram />
      </a>
      <a href="" class="me-4 text-reset">
       <RiLinkedinFill />
      </a>
      <a href="" class="me-4 text-reset">
       <SlSocialGithub />
      </a>
    </div>
  </section>
  <marquee style={{color:"black"}}> DEVELOPERS</marquee>
  <section className="" style={{backgroundColor:"black"}}>
    <div className="container text-center text-md-start mt-5" style={{color:"white"}}>
      <div class="row mt-3">
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
          <i class="fas fa-gem me-0"></i>Company Name :-
            <i class="fas fa-gem me-2"></i>Freshers Software Developer
          </h6>
          <p>
            Here you can use rows and columns to organize your footer content. Lorem ipsum
            dolor sit amet, consectetur adipisicing elit.
          </p>
        </div>
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            Products
          </h6>
          <p>
            <a href="#!" class="text-reset">Angular</a>
          </p>
          <p>
            <a href="#!" class="text-reset">React</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Vue</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Laravel</a>
          </p>
        </div>
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            Useful links
          </h6>
          <p>
            <a href="#!" class="text-reset">Pricing</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Settings</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Orders</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Help</a>
          </p>
        </div>
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
          <p></p>
          <p><i class="fas fa-home me-3"></i>Sikar,Rajasthan,India</p>
          <p>
            <i class="fas fa-envelope me-3"></i>
            info@example.com
          </p>
          <p><i class="fas fa-phone me-3"></i> +91 9024000000</p>
          <p><i class="fas fa-print me-3"></i> +91 9024000000</p>
        </div>
      </div>
    </div>
  </section>

    <div class="text-center p-4" style={{backgroundColor:"dddd(0, 0, 0, 0.05);"}}>
    © 2021 Copyright:
    <a class="text-reset fw-bold" href="https://mdbootstrap.com/">MDBootstrap.com</a>
  </div>
</footer>
 </>
  )
}

export default Footer
