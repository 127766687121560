import React from 'react'

const Events = () => {
  return (
    <>
      Events
    </>
  )
}

export default Events
