import React from 'react'

const About = () => {
  return (
    <>
      <div className="container my-5">
        {/* Top Section: Image and Paragraph */}
        <div className="row align-items-center mb-5">
          {/* Left: Image */}
          <div className="col-md-6">
            <img
              src="https://trialfinity.com/images/about_us.jpg" alt="img" style={{width:"100%"}}/>
            
          </div>

          {/* Right: Paragraph */}
          <div className="col-md-6">
            <p>
              Trialfinity is a multi-disciplinary clinical research site that
              brings many years of experience and leadership in the research
              community to the Hamilton, Trenton area. We bring together a
              diverse team of researchers, clinicians, scientists, and industry
              partners with expertise in various fields of healthcare and
              biomedical research. Our collective knowledge and experience in
              clinical trial allow us to tackle complex healthcare challenges
              from multiple perspectives, driving innovative solutions and
              groundbreaking discoveries..
              </p>
            <p>
              Our clinical research expertise in conducting Phase I-IV clinical
              trial helps us to build trust and strong relationship with our
              community. And our commitment to quality and integrity of data is
              paramount which helps pharmaceutical and biotechnology to bring
              innovative medicine and enhance quality of patient lives.
            </p>
          </div>
        </div>

        {/* Additional Content Below */}
        <div className="mb-5">
          <h2 style={{ color: "#0c7bc0", fontWeight: "600", padding: "10px" }}>
            Features
          </h2>
          <p>
            Trialfinity has a 2,500-square-foot dedicated research facility in
            Hamilton, New Jersey. Our building is conveniently located near
            major medical centers and is only 15 miles from the Trenton Airport.
          </p>

          <div className="mb-5">
          <h2 style={{ color: "#0c7bc0", fontWeight: "600", padding: "10px" }}>Facility Features</h2>
            <ul>
              <li>
                Fully-alarmed, 24-hour monitored security system with selective
                pin access
              </li>
              <li>
                Dedicated monitoring rooms with high-speed Internet, copier,
                phone, and fax
              </li>
              <li>24-hour dedicated emergency contact phone</li>
              <li>Conference room with access to internet</li>
              <li>Fully-equipped examination rooms</li>
              <li>
                Climate-controlled and secured drug storage with limited key
                access
              </li>
              <li>Climate-controlled and secure on-site records storage</li>
              <li>Dedicated coordinator offices</li>
              <li>Infusion Suite</li>
            </ul>
          </div>
          <div className="mb-5">
            <h2 style={{ color: "#0c7bc0", fontWeight: "600", padding: "10px" }}>Diagnostic/Clinic Equipment</h2>
            <ul>
              <li>State-of-the-art CLIA waived laboratory</li>
              <li>
                On-site emergency equipment including AED, oxygen, crash cart,
                and medications (IV fluids, steroids, epinephrine, ASA,
                inhalers)
              </li>
              <li>Ultra So-Lo freezer (-20° and -80°)</li>
              <li>Locked refrigerator and freezer</li>
              <li>Data log tag 24/7 temperature monitoring system</li>
              <li>IV/Infusion Capabilities</li>
              <li>Centrifuge & Refrigerated Centrifuge Incubator</li>
            </ul>
          </div>
          <div className="mb-5">
            <h2 style={{ color: "#0c7bc0", fontWeight: "600", padding: "10px" }}>Affiliated Diagnostic Center Capabilities</h2>
            <ul>
              <li>Ultrasound</li>
              <li>Echocardiogram</li>
              <li>Endoscopy</li>
              <li>Ultra-fast CT</li>
              <li>MRI</li>
              <li>Pulmonary Function Testing</li>
              <li>PSG</li>
              <li>Liquid Nitrogen Storage</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
