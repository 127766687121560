import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./ComponentOne/Home";
import About from "./ComponentOne/About";
import Department from "./ComponentOne/Department";
import Admission from "./ComponentOne/Admission";
import Events from "./ComponentOne/Events";
import Contact from "./ComponentOne/Contact";
import Login from "./ComponentOne/Login";
import Header from "./ComponentOne/Header";
import Footer from "./ComponentOne/Footer";

function App() {
  return (
    <>
      <Router>
        <Header/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About />} />
          <Route path="/department" element={<Department />} />
          <Route path="/admission" element={<Admission />} />
          <Route path="/events" element={<Events />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
        </Routes>
        <Footer/>
      </Router>
    </>
  );
}

export default App;