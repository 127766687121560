import React from 'react'
import { FaPhoneAlt } from 'react-icons/fa';
import { FaAddressCard } from 'react-icons/fa6';
import { MdAccessTimeFilled, MdEmail } from 'react-icons/md';

const Contact = () => {
  return (
    <>
    <div className="container my-5">
      <div className="row">
        {/* Left Side Content */}
        <div className="col-md-6">
          <h2>Contact Information</h2>
          <div className="row">
            {/* Row 1 */}
            <div className="col-md-6 d-flex align-items-start mb-3">
              <FaAddressCard style={{ fontSize: "30px", marginRight: "10px", color: "#007bff" }} />
              <div>
                <strong>Address:</strong>
                <p>Sikar, Rajasthan, India</p>
                <iframe src="https://maps.app.goo.gl/tWH5ToCtbPmSHSr59" width="500"
                height="300" ></iframe>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-start mb-3">
              <FaPhoneAlt style={{ fontSize: "30px", marginRight: "10px", color: "#28a745" }} />
              <div>
                <strong>Phone:</strong>
                <p>+91 9034 567 890</p>
              </div>
            </div>

            {/* Row 2 */}
            <div className="col-md-6 d-flex align-items-start mb-3">
              <MdEmail style={{ fontSize: "30px", marginRight: "10px", color: "#dc3545" }} />
              <div>
                <strong>Email:</strong>
                <p>ankitkumawat@gmail.com</p>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-start mb-3">
              <MdAccessTimeFilled style={{ fontSize: "30px", marginRight: "10px", color: "#ffc107" }} />
              <div>
                <strong>Working Hours:</strong>
                <p>Mon-Fri, 9AM - 5PM</p>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side Form */}
        <div className="col-md-6">
          <h2>Get in Touch</h2>
          <form>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Enter your name"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="mobile number" className="form-label">
                Mobile Number
              </label>
              <input
                type="number"
                className="form-control"
                id="number"
                placeholder="Enter your mobile number"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                id="message"
                rows="4"
                placeholder="Write your message here"
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
    </>
    )
    }
    

export default Contact;
