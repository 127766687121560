import React from 'react'

const Department = () => {
  return (
    <>
      Department
    </>
  )
}

export default Department
